const validate = {
  required: { required: true, message: '请填写必填项', trigger: 'onChange' },
  checkPhone: { pattern: /^[1]\d{0,10}$/, trigger: 'onChange', message: '请输入正确手机号' },
  character: { pattern: /^[\u4E00-\u9FA5\u3400-\u4DB5A-Za-z0-9]*$/, trigger: 'onChange', message: '仅支持输入中文/英文/数字' },
  commonStr: { pattern: /^[\u4E00-\u9FA5\u3400-\u4DB5A-Za-z0-9.()（）\s\·]+$/, trigger: 'onChange', message: '请勿输入特殊字符' },
  checkNum: { pattern: /^[0-9]\d*$/, trigger: 'onChange', message: '请输入数字' },
  checkInt: { pattern: /^[1-9]+[0-9]*$/, trigger: 'onChange', message: '请输入正整数' },
  checkPrice: { pattern: /^[1-9]\d{0,6}(\.\d{1,2})?$|^0(\.\d{1,2})?$/, trigger: 'onChange', message: '最多只能输入7位整数位，小数点后至多2位' },
  normalStr: { pattern: /^[a-zA-Z0-9]+$/, trigger: 'onChange', message: '请输入英文或者数字' },
  mustPhone: { pattern: /^1[3456789]\d{9}$/, trigger: 'onBlur', message: '请输入正确的11位手机号' },
  checkEn: { pattern: /^[a-zA-Z0-9()-_]+$/, trigger: 'onChange', message: '仅支持英文/数字/常用英文字符(_-)' },
  checkTelephone: { pattern: /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/, trigger: 'onBlur', message: '请输入正确的固话(区号-电话号码)' },
  checkIdNumber: { pattern: /^([1-9]\d{16}[0-9xX]|[1-9]\d{14})$/, trigger: 'onBlur', message: '请输入正确的身份证号码' },
  age:{ pattern:/^(?:[1-9][0-9]?|1[01][0-9]|200)$/, message: '请输入合法的年龄' },
  checkMoneyInput: { pattern: /^¥\d*$/, trigger: 'onChange', message: '请输入正整数' },
  vin: { pattern: /^[a-zA-Z0-9]{17}/, trigger: 'onChange', message: '请输入17位英文或者数字' },
  subscription: { pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, trigger: 'onChange', message: '请输入正数，小数点后至多2位' },
  priceNew: { pattern: /^(([1-9][0-9]*)|(([0]\.{1}([0-9]|0[1-9]{1}|[1-9][0-9]?)|[1-9][0-9]*\.{1}([0-9]|0[1-9]{1}|[1-9][0-9]|[0][0]?))))$/, trigger: 'onBlur', message: '请输入正数，小数点后至多2位' },
  plateNumber: {
    pattern: /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([A-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[A-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/,
    trigger: 'onChange',
    message: '请输入合法的车牌号'
  }
}
export {
  validate
}
