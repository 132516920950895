<template>
    <div ref="calendarBox" class="calendarBox">
        <div
            v-for="(item, index) in calendarList"
            :ref="refName+index"
            :key="index"
            :class="['calendar-i', item.calendar === activeDay ? 'today' : (activeDate.length && !activeDate.includes(item.calendar)) ? 'disabled' : '']" 
            @click="changeTime(item, index)">
            <div class="text">{{ item.text }}</div>
            <div v-if="item.calendar === activeDay" class="calendar">{{ item.calendar | timeFormat('MM-DD') }}</div>
            <div v-else class="time">{{ item.time }}</div>
            <div class="value">{{ item.value > 0 ? item.value : '' }}</div>
        </div>
    </div>
</template>
<script>
import dayjs from 'dayjs'

export default {
  name: 'CalendarLine',
  props: {
    defaultDay: {
      type: String,
      default: ()=> dayjs().format('YYYY-MM-DD')
    },
    minDate: { // 日历展示的最早和最晚时间,默认为一个月
      type: Date,
      default: ()=> dayjs().toDate()
    },
    maxDate: {
      type: Date,
      default: ()=> dayjs().add(1,'month').toDate()
    },
    calendarValue: { // 需要展示的预约/待办数字  格式be like: {'2024-02-13': 6}
      type: Object,
      default: ()=>{}
    },
    activeDateRange:{ // 可选择的最小日期 ['2024-12-24','2024-12-25']
      type:  Array,
      default: ()=>[]
    }
  },
  data(){
    return {
      calendarList: [],
      activeDate: [],
      activeDay: '',
      refName: 'calendar-i',
    }
  },
  watch:{
    // 赋值日期下方的数字
    calendarValue:{
      handler(val){
        if (this.calendarList.length===0) return
        this.calendarList.forEach(({ calendar,value },idx) => {
          if (val[calendar]){
            this.$set(this.calendarList[idx],'value',val[calendar])
          } else {
            this.$set(this.calendarList[idx],'value',0)
          }
        })
      },
      deep:true,
      immediate: true
    },
    // 范围内的日期可选，其余日期禁选
    activeDateRange: {
      handler(val){
        if (this.calendarList.length === 0) return
        if (val.length===0){
          this.activeDate = []
          return
        }

        const [activeMinDate,activeMaxDate] = val
        let start = dayjs(activeMinDate)
        const end = dayjs(activeMaxDate)
        if (start.isAfter(end)){
          throw new Error('开始日期应小于结束日期')
        }

        const temp = []
        while (start.isSame(end, 'day') || start.isBefore(end, 'day')) {
          temp.push(start.format('YYYY-MM-DD'))
          start = start.add(1, 'day')
        }
        this.activeDate = temp
        // 判断当前日期是否在activeDate范围内
        if (this.activeDate.includes(dayjs().format('YYYY-MM-DD'))){
          this.activeDay = dayjs().format('YYYY-MM-DD')
        } else {
          this.activeDay = ''
        }
      },
      deep:true,
      immediate: true
    }
  },
  mounted(){
    this.getDateList()
    this.activeDay = this.defaultDay
  },
  methods:{
    // 生成日历
    getDateList() {
      let start = dayjs(this.minDate)
      const end = dayjs(this.maxDate)
      const dateList = []

      const weekdays = ['日', '一', '二', '三', '四', '五', '六'] 

      while (start.isSame(end, 'day') || start.isBefore(end, 'day')) {
        dateList.push({
          calendar: start.format('YYYY-MM-DD'),
          time: start.format('DD'),
          text: weekdays[start.day()],
          value: 0
        })
        start = start.add(1, 'day')
      }
      this.calendarList = dateList
    },
    // 改变时间
    changeTime(date,index) {
      if (this.activeDate.length && !this.activeDate.includes(date.calendar)){
        return
      }
      this.activeDay = date.calendar
      const parentWidth = this.$refs.calendarBox.offsetWidth
      const minLeft = parentWidth * 0.2
      const maxLeft = parentWidth * 0.8
      const offsetLeft = this.$refs[`calendar-i${index}`][0].offsetLeft
      const parentOffset = document.querySelector('.calendarBox').scrollLeft
      if (Math.abs(offsetLeft - parentOffset) < minLeft) {
        document.querySelector('.calendarBox').scrollLeft = offsetLeft - parentWidth * 0.5
      }
      if (Math.abs(offsetLeft - parentOffset) > maxLeft) {
        document.querySelector('.calendarBox').scrollLeft = offsetLeft - (offsetLeft - parentOffset) * 0.5
      }
      this.$emit('changeTime',date.calendar)
    },
  }
}
</script>
<style lang="less" scoped>
.calendarBox{
    display: flex;
    width: 100%;
    overflow-x: scroll;
    height: auto;
    justify-content: space-between;
    position: relative;
    padding: 10px 0px;
    .calendar-i{
      width: 14.285%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      height: 80px;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      user-select: none;
      &.today{
        background-color: #0D171A;
        color: #EED484;
        border-radius: 6px;
        overflow: hidden;
      }
      &.disabled{
        color: #E5E5E5;
        cursor: not-allowed;
      }
      .time{
        margin: 6px 0;
      }
      .calendar{
        margin: 6px 0;
      }
      .text{
        font-size: 14px;
        font-weight: 500;
      }
      
      .value{
        font-weight: bold;
        height: 18px;
        line-height: 18px;
      }
    }
  }
</style>