<template>
  <div class="selectShopBox">
    <van-dropdown-menu v-if="shopList" active-color="#B9921A">
      <van-dropdown-item v-model="activeShop" :disabled="disabledMark" clearable :options="shopList">
      </van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from '@/store'
export default {
  props: {
    disabledMark: { // 是否可以切换
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      activeShop: '',
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'selectedShopId']),
    shopList() {
      let { dealers = [] } = this.userInfo
      dealers = dealers.filter(({ roleLogos=[] }) => { return roleLogos.length > 0 })
      return dealers.map(({ name, id, ...querys }) => { return {
        id,
        name,
        value: id,
        text: name,
        ...querys
      } })
    }
  },
  watch: {
    shopList: {
      handler(val) {
        this.activeShop = val[0].id
      },
      immediate: true,
      deep: true
    },
    selectedShopId: {
      handler(val, oldVal) {
        if (val && val !== oldVal && this.shopList.findIndex(({ id }) => { return id === val }) !== -1 ) {
          this.activeShop = val
        }
      },
      deep: true
    },
    activeShop: {
      handler(val) {
        if (val) store.commit('app/setSelectedShopId', val)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.selectShopBox{
  position: sticky;
  top: 0;
  z-index: 100;
  /deep/ .van-dropdown-menu {
    height: 48px;
    .van-dropdown-menu__bar{
      .van-dropdown-menu__item{
        justify-content: flex-start;
      }
      .van-dropdown-menu__title{
        padding-left: 16px;
      }
      .van-dropdown-menu__title::after{
        border-color: transparent transparent @black @black
      } 
    }
  }
}
</style>