import request from '@/utils/request'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'
import Server from './server'

const CarManageServices = {
  /**
   * 获取线索列表
   */
  async getList(params) {
    const result = await request.post(`${Server.Test_Drive}/client/v1/car/page`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },

  /**
   * 车辆处理
   */
  async carOperatePost(params) {
    const result = await request.post(`${Server.Test_Drive}/client/v1/car/operate`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },

  /**
   * 车辆轨迹
   */
  async getCarTrips(params) {
    return request.get(`${Server.Test_Drive}/client/v1/car/record`, { params }).then(thenResolve)
  },

  /**
   * 查看试驾车排程接口
   */
  async carScheduleDayPost(params) {
    const result = await request.post(`${Server.LEAD_SERVICE}/v1/testDrive/car/list/day`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },

  /**
   * 日历上排程数量
   */
  async getCalendarList(params) {
    const result = await request.post(`${Server.LEAD_SERVICE}/v1/testDrive/car/list/calendar`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data['appointmentCalendar']
  },
  /**
   * 试驾活动列表
   */
  async activityPagePost(params) {
    const result = await request.post(`${Server.Test_Drive}/client/v1/activity/list/execute`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },
  // 集中试驾活动查询
  async vehicleInfoPost(params) {
    const result = await request.post(`${Server.Test_Drive}/client/v1/activity/getVehicleInfo`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },
  // 根据集中试驾id查活动列表
  async getActivityById(params) {
    return request.get(`${Server.Test_Drive}/client/v1/activity/list`, { params }).then(thenResolve)
  },
  getBackendCarPage(params) {
    return request.post(`${Server.Test_Drive}/backend/v1/car/page`, params).then(thenResolve)
  }
}

// 更新试驾记录信息
export const queryTestDriveUpdate = (params = {}) => request.post(`${Server.Test_Drive}/client/v1/record/update`, params).then(thenResolve)

// 获取礼物领取配置
export const showReceiveGift = (params) => request.post(`${Server.Test_Drive}/client/v1/record/showReceiveGift`, params).then(thenResolve)

// 礼物领取
export const editReceiveGift = (params) => request.post(`${Server.Test_Drive}/client/v1/record/editReceiveGift`, params).then(thenResolve)

export default CarManageServices
