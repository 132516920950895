import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'
import { Toast } from 'vant'

// 翻页
export const getAppointmentPage = (params={}) =>{
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/list/page`,params).then(thenResolve)
}
// 日历 
export const getCalendarList = (params={}) =>{
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/list/calendar`,params).then((res)=>{
    const { code, data, msg, message } = res || {}
    if (code === 0) {
      return data['appointmentCalendar'] || {}
    }
    if (code !== 14) {
      Toast(msg || message)
    }
    throw new Error(res?.data || '')
  })
}
// 获取某天所有预约信息
export const getAppointmentDay = (params={}) =>{
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/list/day`,params).then(thenResolve)
}
// 获取页签预约数量信息
export const getAppointmentNum = (params={}) =>{
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/list/table`,params).then(thenResolve)
}
// 分配
export const getTestDriveAssign = (params={}) =>{
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/assign`,params).then(thenResolve)
}

// 预约审核
export const appointmentReviewPost = (params) => {
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/review`,params).then(thenResolve)
}

// 上门试驾排程
export const driveUpdatePost = (params)=>{
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/update`,params).then(thenResolve)
}

// 新建预约试驾排程
export const addTestDrive= (params)=>{
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/add`, params).then(thenResolve)
}

export const testDriveDetail= (id)=>{
  return request.get(`${SERVER.LEAD_SERVICE}/v1/appointment/testDrive/detail?id=${id}`, { }).then(thenResolve)
}