<template>
  <div class="address" >
    <van-form ref="form" validate-first :labelWidth="labelWidth"  :show-error-message="false">
      <van-field :value="getAddressObj.name" 
        :inputAlign="inputAlign" 
        :required="isRequired" 
        :rules="rules" 
        readonly 
        name="address" 
        :label="isShowLabel ? label : ''" 
        :placeholder="placeholder" 
        :disabled="isDisabled"
        :class="[isDisabled ? 'appointment_disabled' : '']" 
        @click="showCityChoose">
        <template #right-icon>
          <template v-if="!isDisabled">
            <van-icon v-if="getAddressObj.name" name="close" @click.stop="onCityChooseConfirm" />
            <van-icon v-else name="arrow" />
          </template>
        </template>
      </van-field>
      <!-- 省市区选择 -->
      <van-popup v-model="isCityChoose" position="bottom">
        <van-cascader :value="getAddressObj.code" :title="$t('请选择所在地区')" :options="areaOptions" :field-names='cityFieldName' @close="isCityChoose = false" @change="cityChange" />
      </van-popup>
    </van-form>
  </div>
</template>
<script>
// import followUpApi from '@/api/followUp'
import baseDataServices from '@/services/baseDataServices'
import { i18n } from '@/main'
export default {
  model:{
    prop: 'formData',
    event: 'change'
  },
  props:{
    formData:{
      type: Object,
      default:()=>({})
    },
    isRequired:{
      type: [String,Boolean,Number],
      default: false
    },
    rules:{
      type:Array,
      default:()=>[]
    },
    isDisabled: {
      type: [String,Boolean,Number],
      default: false
    },
    getContainer:{
      type: String,
      default:''
    },
    inputAlign:{
      type: String,
      default:'left'
    },
    placeholder:{
      type: String,
      default: i18n.t('请选择')
    },
    label:{
      type: String,
      default:'省市区'
    },
    labelWidth:{
      type: String,
      default:'6.2em'
    },
    keys:{
      type: Object,
      default:()=>({})
    },
    isShowDistrict:{ // 为 false 时只展示省市，默认展示省市区
      type: Boolean,
      default: true
    },
    isShowLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isCityChoose: false,
      areaOptions:[],
      cityFieldName: {
        text: 'name',
        value: 'code',
      },
      selfKeys:{
        cityCode:'userCityCode',
        city:'userCity',
        provinceCode:'userProvinceCode',
        province:'userProvince',
        countyCode:'userCountyCode',
        county:'userCounty',
      }
    }
  },
  computed:{
    getAddressObj() {
      const { selfKeys, formData } = this
      const arr = []
      formData[selfKeys.province] && arr.push(formData[selfKeys.province])
      formData[selfKeys.city] && arr.push(formData[selfKeys.city])
      formData[selfKeys.county] && arr.push(formData[selfKeys.county])
      return {
        name: arr.join('/'),
        code: formData[selfKeys.countyCode] || formData[selfKeys.cityCode] || formData[selfKeys.provinceCode]
      }
    },
  },
  watch:{
    keys:{
      handler(val){
        if (val){
          Object.assign(this.selfKeys,val)
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getAreaList(0) // 获取省市区
  },
  methods:{
    // 获取省市区信息
    async getAreaList (id) {
      const res = await this.getAddress(id)
      this.areaOptions = res
    },
    // 获取省市区--调接口
    getAddress (parentId) {
      return baseDataServices.getAreaList({ parentId })
      // .then(res=>{
      //   res.map(item=>{
      //     item['children'] = []
      //   })
      //   return res
      // })
    },
    async showCityChoose() {
      if (this.isDisabled) return
      const { selfKeys, formData } = this
      const options = this.areaOptions
      if (formData[selfKeys.provinceCode]){
        const oneIndex = options.findIndex(item=>item.code === formData[selfKeys.provinceCode])
        if (oneIndex !== -1 && formData[selfKeys.cityCode] && formData[selfKeys.cityCode] !== '-1'){
          const nowObj = options[oneIndex]
          if (!nowObj.children || (nowObj.children && nowObj.children.length === 0)) {
            const res = await this.getAddress(formData[selfKeys.provinceCode])
            nowObj.children = res
            const twoIndex = res.findIndex(item=>item.code === formData[selfKeys.cityCode])
            if (twoIndex !== -1 && formData[selfKeys.countyCode] && formData[selfKeys.countyCode]!== '-1'){
              const res = await this.getAddress(formData[selfKeys.cityCode])
              nowObj.children[twoIndex].children = res
            }
          }
        }
      }
      this.isCityChoose = true
    },
    // 选择城市时
    cityChange ({ value, selectedOptions, tabIndex }) {
      console.log(value, selectedOptions, tabIndex, 888)
      this.onCityChooseConfirm(selectedOptions || [],tabIndex)
      // 不展示区域时则不请求
      if (!this.isShowDistrict&&tabIndex==1) return

      if (!selectedOptions[tabIndex].children || (selectedOptions[tabIndex].children && selectedOptions[tabIndex].children.length === 0)) {
        if (tabIndex < 2) {
          this.getAddress(value).then(res=>{
            this.$set(selectedOptions[tabIndex], 'children', res || [])
          })
        }
      }
      
    },
    onCityChooseConfirm(selectedOptions = [],tabIndex) {
      const { selfKeys } = this
      const formData = JSON.parse(JSON.stringify(this.formData))
      Object.assign(formData,{
        [selfKeys.provinceCode]:selectedOptions[0]?.code || '',
        [selfKeys.province]:selectedOptions[0]?.name || '',
        [selfKeys.cityCode]:selectedOptions[1]?.code || '',
        [selfKeys.city]:selectedOptions[1]?.name || '',
        [selfKeys.countyCode]:selectedOptions[2]?.code || '',
        [selfKeys.county]:selectedOptions[2]?.name || '',
      })
      // if (tabIndex === 2){
      this.$emit('change',formData)
      // }
    },
  }
}
</script>
<style lang="less" scoped>
.address{
  position: relative;
  &:after{
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

.appointment_disabled{
  .van-cell__title{
    color: #c8c9cc;
  }
  .van-field__body{
    .van-field__control{
      color: #c8c9cc;
    }
  }
}
</style>
